<div class="image col d-flex align-items-center justify-content-center">
    <img
      src="../../assets/img/denmaar_full_logo.png"
      alt="DENmaar" style="width: 150px;"
    />
    

</div>
<div class="text col d-flex align-items-center justify-content-center">  
    <h5 style="
    color: #313E52;
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;">Telehealth<span style="font-size: 11px;
    color: cornflowerblue;
    padding-left: 10px;">{{'v'+appVersion}}</span></h5>
</div>
<div>
  <h5 style="color: cornflowerblue; position: absolute; right: 10px; top: 16px; font-size: 20px; z-index:9999"><a href="http://denmaar-telehealth-faq.s3-website-us-west-2.amazonaws.com/" target="_blank" title="Help & FAQ"><i class="fa fa-question-circle"></i> Help</a></h5>
</div>
<router-outlet>
</router-outlet>

