import { Component, OnInit } from '@angular/core';
import { ErrorMessageService } from '../utils/services/error-message.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  errorMessage:string = '';

  constructor(private errorMessageService:ErrorMessageService) {
    this.errorMessage = this.errorMessageService.getErrorMessage();
   }

  ngOnInit(): void {
  }

}
