<div class="container-fluid justify-content-center">
    <!-- <div class="row justify-content-center">
        <img
        src="../../assets/img/denmaar_full_logo.png"
        alt="DENmaar"
      />
    </div> -->
    
    <div class="row justify-content-center">
        
        <div class="col">
            <form [formGroup]="guestForm" (ngSubmit)="onSubmit()" style="margin-top:6rem">
                <h5 class="text-white text-center">
                    Enter your name to join the Telehealth session
                </h5>

                <div class="d-flex align-items-center justify-content-center">
                    <div class="form-group mb-0">
                        <input type="text" class="form-control small" placeholder="Enter your Name"
                            formControlName="UserName" maxlength="50">
                        <div *ngIf="submitted && f.UserName.errors" style="color:red">
                            <div *ngIf="f.UserName.errors.required">Name is required</div>
                        </div>
                    </div>

                </div>

                <div class="d-flex align-items-center justify-content-center mt-3">
                    <div class="form-group mb-0">
                        <button type="submit" [disabled]="loading" class="btn btn-primary width100">
                            <span
                              *ngIf="loading"
                              class="spinner-border spinner-border-sm mr-1"
                            ></span>
                            Join Meeting
                          </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>