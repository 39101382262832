import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  errorMessage:string = '';

  constructor() { }

  setErrorMessage(message:string){
    this.errorMessage = message;
  }

  getErrorMessage(){
    return this.errorMessage;
  }
  
}
