import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private listHeader = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
  });
  private commonHeader = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  constructor(
    private http: HttpClient
  ) { }

  postData(url:string,data:any,headerType:number = 1){
    let apiUrl = environment.apiUrl+url; 
    return this.http.post(apiUrl, data,{ headers:this.getHeader(headerType)}).pipe(
          catchError((err:HttpErrorResponse): Observable<any> =>{
              throw err
          })
      );
  }

  getData(url:string,headerType:number = 1){
    
    let apiUrl = environment.apiUrl+url; 
    return this.http.get<any>(apiUrl,{ headers:this.getHeader(headerType)}).pipe(
          catchError((err:HttpErrorResponse): Observable<any> =>{
              throw err
          })
      );
  }
  

  getHeader(headerType:number){
    if(headerType == 1){
      return this.listHeader;
    }
    else if(headerType == 3){
      return {};
    }
    else{
      return this.commonHeader
    }
  }
}
