import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '../utils/services/api.service';
import { SecureStorageService } from '../utils/services/secure-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {
  guestForm: UntypedFormGroup
  submitted: boolean = false
  loading:boolean = false
  constructor(
    private fb: UntypedFormBuilder,
    private apiService:ApiService,
    private secureStorage:SecureStorageService,
    private router:Router

  ) { }

  ngOnInit(): void {
    this.guestForm = this.fb.group({
      UserGUID: [this.secureStorage.get('meeting_details').meetingId, [Validators.required]],
      UserName: ['', [Validators.required]]
  })
  }

  get f() { return this.guestForm.controls; }


  onSubmit() {
    this.submitted = true
    if (this.guestForm.valid) {
        this.loading = true;
        this.apiService.postData('/api/GroupCall/ModifyUserMeetingDetails',this.guestForm.value,2).subscribe((data:any)=>{
          this.loading = false;
          let meeting_details = this.secureStorage.get('meeting_details');
          meeting_details.name = this.guestForm.value.name;
          meeting_details.token = data.Data.Token;
          meeting_details.channel = data.Data.RoomName;
          this.secureStorage.set('meeting_details',meeting_details);

          this.router.navigate(['meeting']);
        },(error)=>{
          this.loading = false;      
        });
    }
}
}
