import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecureStorageService } from './utils/services/secure-storage.service';
import { ApiService } from './utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import  packageInfo  from '../../package.json';
import { ErrorMessageService } from './utils/services/error-message.service';
import { isSupported } from 'twilio-video';
import { BrowserCheckService } from './utils/services/browser-check.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'denmaar';
  meetingId: string;
  appVersion = packageInfo.version;
  constructor(
    private route: ActivatedRoute,
    private secureStorage: SecureStorageService,
    private apiService: ApiService,
    private router: Router,
    private errorMessageService: ErrorMessageService,
    private browserCheckService: BrowserCheckService
  ) {}

  ngOnInit() {
    let browserData = this.browserCheckService.checkBrowser();
    console.log(browserData);
    let isBrowserVersionSupported = true;
    if (browserData[0] == 'Safari' && browserData[1] < 14) {
      isBrowserVersionSupported = false;
    }

    if (!isSupported || !isBrowserVersionSupported) {
      this.errorMessageService.setErrorMessage(
        'Your browser is not supported, Please  ' + !isSupported
          ? 'use a different browser'
          : 'update your current browser' + 'to join the meeting'
      );
      this.router.navigate(['/error']);
    } else {
      this.route.queryParams.subscribe((params) => {
        this.meetingId = params['meeting_id'];
        if (this.meetingId) {
          this.verifyMeetingId(this.meetingId);
        }
      });
    }
  }

  hasRoute(route: string) {
    return this.router.url.includes(route);
  }

  verifyMeetingId(meetingId) {
    this.apiService
      .getData('/api/GroupCall/GetUserMeetingDetails?UserGUID=' + meetingId)
      .subscribe(
        (data: any) => {
          if (data.Data.IsGuest) {
            this.secureStorage.set('meeting_details', {
              meetingId: meetingId,
              channel: data.Data.RoomName,
              is_guest: true,
              name: '',
              token: '',
            });
            this.router.navigate(['guest']);
          } else {
            this.secureStorage.set('meeting_details', {
              meetingId: meetingId,
              channel: data.Data.RoomName,
              is_guest: false,
              name: data.Data.UserName,
              token: data.Data.Token,
            });
            this.router.navigate(['meeting']);
          }
        },
        (error) => {
          this.errorMessageService.setErrorMessage(error.error.Message);
          this.router.navigate(['error']);
        }
      );
  }
}
