import { NgModule  } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuestComponent } from './guest/guest.component';
import { SuccessPageComponent } from './success-page/success-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
const routes: Routes = [
  {
    path: 'guest',
    component: GuestComponent
  },
  {
    path: 'meeting',
    loadChildren: () => import('./twilio-video/twilio-video.module').then(m => m.TwilioVideoModule)
  },
  {
    path: 'success',
    component: SuccessPageComponent
  },
  {
    path: 'error',
    component: ErrorPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash:true,relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
